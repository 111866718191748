<template>
  <div class="page-content">
    <page-breadcrumb title="Lucky Ticket Events" class="mb-3" />

    <b-card>
      <div class="d-flex">
        <data-create-popup class="ml-auto" :default-data="{ banner: null, occur_date: null }"
          :schema="create_item_schema"
          :create-data-fn="createItem" @create="$refs.item_list.getList()"
        />
      </div>
      <data-table-ssr id="item_list" ref="item_list" :limit-base="9" pagination
        :columns="item_table_fields" :get-list-fn="getList"
        :edit-row-fn="updateItem" :delete-row-fn="deleteItem"
      />
    </b-card>

  </div>
</template>

<script>
import service from "../service_event";

const item_table_fields = [
  { label: 'Banner', field: 'banner', input_type: 'image', editable: true },
  { label: 'Occur Date', field: 'occur_date', input_type: 'date', editable: true },
];

const create_item_schema = [
  {
    cols: 4,
    fields: [
      { label: 'Banner', field: 'banner', input_type: 'image' }
    ],
  },
  {
    cols: 8,
    fields: [
      { label: 'Occur Date', field: 'occur_date', input_type: 'date', validate: { required: true }},
    ],
  }
];

export default {
  data() {
    return {
      item_table_fields,
      create_item_schema,
      filter: {},
    };
  },
  computed: {
    authLanguage() {
      return this.$store.getters["auth/language"];
    },
  },
  watch: {
    filter: {
      handler() {
        this.$refs.item_list.getList();
      },
      deep: true,
    },
  },
  methods: {
    async getList({ limit, page, query, searchTerm }) {
      let filter = _.omitBy(this.filter, (value) => value === null);
      let response = await service.getList({
        query: JSON.stringify({ ...query, ...filter }),
        page, limit
      });
      let list = [], total = 0;
      if (response.data.type === "DATA") {
        list = response.data.data.list;
        total = response.data.data.total;
        this.total = total;
      }
      return { list, total };
    },

    async createItem(data) {
      let new_item = await service.create({...data});
      return new_item;
    },
    async updateItem(row) {
      await service.update({ data: JSON.stringify(row) });
    },
    async deleteItem({id}) {
      await service.delete({id});
      this.$refs.item_list.getList();
    },

  },
};
</script>
