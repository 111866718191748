import api from '@/libs/axios'

export default {
  async getList(params) {
    let response = await api.get('/earns/admin/luckyticket-events', { params })
    return response
  },
  async get(params) {
    let response = await api.get('/earns/admin/luckyticket-events/detail', {
      params,
    })
    return response.data.data || null
  },
  async create(payload) {
    let response = await api.post('/earns/admin/luckyticket-events', payload)
    return response
  },
  async update(payload) {
    let response = await api.put('/earns/admin/luckyticket-events', payload)
    return response
  },
  async delete(payload) {
    let response = await api.delete('/earns/admin/luckyticket-events', { data: payload })
    return response
  },
}
